<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgPlusTextButton></DgPlusTextButton>

	2021-09-24	init

-->

<template>
	<div class="DgPlusTextButton shadow shadow--button" @click="onClickBtn" :class="elmClasses" role="button">
		<div class="DgPlusTextButton__inner">
			<MhIcon class="DgPlusTextButton__icon" :isSpinning="isFetching" :type="isFetching ? 'loader' : 'book-open'"></MhIcon>
			<div class="DgPlusTextButton__text" v-html="title"></div>
			<!--
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import normalizeResponseData from '@/helper/dgNormalizeResponseData.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhIcon from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhIcon/v2/MhIcon.vue'

	export default {
		name: 'DgPlusTextButton',
		components: {
			MhIcon,
		},
		mixins: [ restHandler ],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			slug: {
				type     : [String],
				default  : undefined,
				required : false,
			},
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {
				//btnType : 'loader',
				//btnIsSpinning : false,
				isFetching : false,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			XXXhandleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
			onClickBtn(){
				this.isFetching = true

				EventBus.$emit('fetchArticleAndOpenReader', {
					slug : this.slug,
					callback : ()=>{
						this.isFetching = false
					}
				})
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgPlusTextButton { // debug
		[showBorders2] & {}
		//outline: 1px solid red;
	}
	.DgPlusTextButton { // layout
		position: relative;
		display: inline-flex;
		height: calc( var(--app-button-height) * 1.25 );
		min-width: var(--app-button-width);
		overflow: hidden;

		&__inner {
			position: relative;
			display: flex;
			flex-grow: 1;
			justify-content: center;
			align-items: center;
		}
	}
	.DgPlusTextButton { // styling
		font-size: var(--app-gui-fontSize-s);
		line-height: var(--app-gui-lineHeight-s);
		color: var(--app-button-color);
		transition: var(--app-button-transition);
		background-color: var(--swatch-cta);
		border-radius: calc( var(--app-button-height) * 0.25 );
		padding: var(--app-button-padding);
		max-width: 300px;

		&:hover {
			//animation-name: initial !important;
			transform: var(--app-button-hoverTransform) !important;
			background-color: var(--swatch-cta-darken);
		}
		&:active {
			transform: var(--app-button-activeTransform) !important;
		}

		&__icon { margin-right: 0.5em; flex-shrink: 0; font-size: 2em; }

		&__text { // clamp text
			overflow: hidden;
			hyphens: auto;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		/*
		&::before {
			content: "\A";
			white-space: pre;
		}
		*/
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
