/**
 * Converts JS-Date to YYYY-MM-DD string
 *
 * @param date : undefined or JS-Date
 * @param divider : string '-'
 * @returns formated date string
 */

function formatDate( date, divider = '-' ){
	var d = date ? new Date( date ) : new Date(),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2)
		month = '0' + month;
	if (day.length < 2)
		day = '0' + day;

	return [year, month, day].join( divider )
}

export default formatDate;