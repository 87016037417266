<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgAudioPlayer
			:file="false"
			:text="''"
			:expirationDate="''"
		></DgAudioPlayer>

	2022-03-20	improvement: added expirationDate prop, formated as Ymd
	2021-09-24	init

-->

<template>
	<div class="DgAudioPlayer contentModule" :class="elmClasses" :data-dev-name="'DgAudioPlayer'" v-if="hasFile">
		<div class="DgAudioPlayer__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgAudioPlayer__content contentModule__content" :class="contentElmClasses">
				<template v-if="!isMediaExpired">
					<MhAudio
						class="shadow shadow--button"
						:file="file.url"
						:title="text"
						:autoPlay="false"
						:loop="false"
						:showStop="false"
						:showPlay="true"
						:showDownload="false"
						:showVolume="false"
						:showLoop="false"
						:showMute="false"
					></MhAudio>
				</template>
				<template v-else>
					<DgMediaExpired
						:ratio="'10:1'"
						:type="'audio'"
					></DgMediaExpired>
				</template>
			</div>
		</div>
		<!--
			<pre name="expirationDate" maxheight>{{expirationDate}}</pre>
			<pre name="file" maxheight>{{file}}</pre>
			<pre name="isMediaExpired" maxheight>{{isMediaExpired}}</pre>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhAudio from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhAudio/v1/MhAudio.vue'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'
	import formatDateToYYYYMMDD from '@/helper/formatDateToYYYYMMDD'
	import DgMediaExpired from '@/components/molecules/DgMediaExpired.vue'


	export default {
		name: 'DgAudioPlayer',
		components: {
			MhAudio,
			DgMediaExpired,
		},
		mixins: [ dgContentModuleMixin ],
		props: {
			file: {
				type     : [Object, Boolean],
				default  : false,
				required : false,
			},
			expirationDate: {
				type     : [String],
				default  : '',
				required : false,
			},
			text: {
				type     : [String, Boolean],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--size-' + this.size )

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				return classes
			},
			isMediaExpired(){
				let isExpired = false
				const expirationDateInt = this.expirationDate ? parseInt( this.expirationDate ) : undefined
				const todayDateInt = parseInt( formatDateToYYYYMMDD( undefined, '' ) )

				if( expirationDateInt && expirationDateInt <= todayDateInt ) isExpired = true

				/*
				console.log('expirationDateInt:', expirationDateInt)
				console.log('todayDateInt:', todayDateInt)
				*/

				return isExpired
			},
			hasFile(){
				return this._.isObject( this.file )
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('scroll', this.handleScroll);
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgAudioPlayer { // debug
		[showBorders2] & {}
	}
	.DgAudioPlayer { // layout
		--height: calc( var(--app-button-height) * 1.25 );
		--maxWidth: 400px;
	}
	.DgAudioPlayer { // layout
		// grid
		.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }

		.MhAudio {
			height: 100%;
			height: var(--height);
		}

		@media @mq[sm] {
			// grid
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }
		}
	}
	.DgAudioPlayer { // styling
		.MhAudio {
			--progressBar-height: 5px;
			--progressBar-bgColor: fade( white, 50);
			--progressBarSeeker-bgColor: white;
		}
		.MhAudio {
			font-size: var(--app-gui-fontSize-s);
			line-height: var(--app-gui-lineHeight-s);
			color: var(--app-button-color);
			color: var(--app-button-color);
			transition: var(--app-button-transition);
			background-color: var(--swatch-cta);
			border-radius: calc( var(--app-button-height) * 0.25 );
			padding: var(--app-button-padding);
			max-width: var(--maxWidth);
		}
		.MhAudio:hover {
			background-color: var(--swatch-cta-darken);
		}
		.MhAudio__controlsLeft { padding-right: 0.75em; }
		.MhAudio__controlsMiddle { padding: 0.5em 0; }
		.MhAudio__controlsProgressCurrentTime { opacity: 0.65; }
		.MhAudio__controlsProgressTotalTime { opacity: 0.65; }
		.MhAudio__controlsProgressBarWrapper { margin: 0 0.5em; }

		.MhIcon--type-play { font-size: 1.5em; }
		.MhIcon--type-pause { font-size: 1.5em; }
	}


	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
