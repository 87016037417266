<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhSlideToggle
			:isOpen="true"
			:tag="'div'"
			:minHeight="'0px'"
			@stateChange="(e)=>{}"
		>
			The content that should be slide toggled.
		</MhSlideToggle>

	2022-02-14	init

-->

<template>
	<div class="MhSlideToggle"
		:class="currentClass"
		:is="tag"
		@transitionend="onTransitionEnd"
	><slot></slot></div>
</template>

<script>
	const capitalize = (s) => {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}

	export default {
		name: 'MhSlideToggle',
		props: {
			tag: {
				type     : [String],
				default  : 'div',
				required : false,
			},
			isOpen: {
				type     : [Boolean],
				default  : true,
				required : false,
			},
			minHeight: {
				type     : [String],
				default  : '0px',
				required : false,
			},
		},
		data(){
			return {
				currentState : undefined,
				currentClass : undefined,
				contentHeight : undefined,
			}
		},
		watch: {
			isOpen: {
				handler: function( to, from ) {
					if( to ) this.doOpen()
					else this.doClose()
				},
			},
			currentState: {
				handler: function( to, from ) {
					//console.log('watch.currentState', to)

					this.currentClass = this.$options.name + '--is' + capitalize( to )
					this.$emit('stateChange', to)
				},
			},
		},
		computed: {},
		methods: {
			doClose(){ // close: animate to prop minHeight
				// set current state
				this.currentState = "closing"
				// set elm height to prop minHeight as style
				this.$el.style.height = this.minHeight

				// if there is no transition duration we set the current state immediately
				const hasTransitionDuration = parseFloat(window.getComputedStyle(this.$el).transitionDuration) > 0 ? true : false
				if (!hasTransitionDuration) this.currentState = 'closed'
			},
			doOpen(){ // open: animate to content height
				// set current state
				this.currentState = "opening"
				// get and store unfolded content height
				this.detectContentHeight()
				// this forces redraw. needed to get the height transition
				this.$el.getBoundingClientRect()
				// set detected height as style
				this.$el.style.height = this.contentHeight + 'px'
				// if there is no transition duration we set the current state immediately
				const hasTransitionDuration = parseFloat(window.getComputedStyle(this.$el ).transitionDuration) > 0 ? true : false
				if(!hasTransitionDuration) this.currentState = 'open'
			},
			detectContentHeight(){ // get and store content height
				// store height to add it after
				const wasHeight = this.$el.style.height

				// temp disable any transitions
				this.$el.style.transition = 'none'
				// unset any height
				this.$el.style.height = ''

				// set detected content height
				this.contentHeight = this.$el.getBoundingClientRect().height

				// unset prev disabled transitions
				this.$el.style.transition = ''
				// reset to prev stored height
				this.$el.style.height = wasHeight
			},
			onTransitionEnd(e){
				const isValidProperty = e.propertyName === 'height'

				if ('opening' === this.currentState && isValidProperty) this.currentState = 'open'
				if ('closing' === this.currentState && isValidProperty) this.currentState = 'closed'
			},
		},
		mounted(){
			// temp disable any transition to force quick state on start
			this.$el.style.transition = 'none'
			// set needed overflow style permanently
			this.$el.style.overflow = 'hidden'

			// set initial toggle state depending on prop
			if (this.isOpen) this.doOpen()
			else this.doClose()

			// unset prev disabled transitions
			this.$el.style.transition = ''
		},
	}
</script>

<style lang="less">
	.MhSlideToggle { // debug
		[showBorders4] & {
			outline: 1px solid red;
			background-color: fade( red, 25 );
		}
	}
	.MhSlideToggle { // layout
		//padding: 2em;
	}
	.MhSlideToggle { // styling
		transition: all 0.25s ease;
	}
</style>
