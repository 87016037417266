<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DbBu></DbBu>

	2021-09-24	init

-->

<template>
	<div class="DbBu richText fontStyle fontStyle--s" :class="elmClasses" v-if="_caption || _copyright">
		<div class="DbBu__caption" v-if="_caption">
			<span v-html="_caption"></span>
		</div>
		<div class="DbBu__copyright" @click="isVisibleCopyright = !isVisibleCopyright" v-if="_copyright">
			<a class="DbBu__copyrightBtn noSelect" title="Copyright">©</a>
			<span class="DbBu__copyrightText" v-if="isVisibleCopyright" v-html="_copyright"></span>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DbBu',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			caption: {
				type     : [String],
				default  : '',
				required : false,
			},
			copyright: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {
				isVisibleCopyright : false,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			_caption(){
				let caption = this._.trim( this.caption )
				return caption
			},
			_copyright(){
				let copyright = this._.trim( this.copyright )
				return copyright
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DbBu { // debug
		[showBorders2] & {}
		//&__text { background-color: fade( blue, 35 );}
		//&__copyright { background-color: fade( green, 35 );}
	}
	.DbBu { // layout
	}
	.DbBu { // styling
		.DgVideoPlayer & { padding-top: 0.75em; }
		.DgImage--textPosition-below & { padding-top: 0.75em; }
		.DgImages & { padding-top: 0.75em; }
		.DgLightboxLayer & { padding-top: 0.75em; }

		&__text {
			padding-bottom: 0.5em;
		}
		&__copyright &__copyrightBtn {
			padding-right: 1em;
			color: inherit;
			text-decoration: none;
			opacity: 0.5;
			transition: all 0.1s ease;
			&:hover { opacity: 1; text-decoration: none; }
		}
		&__copyrightText {
			opacity: 0.5;
			white-space: pre-wrap;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
