//import inViewportMixin from './vue-in-viewport-mixin'

export default {
	// Public interface
	props: {},
	// Bindings that are used by the host component
	data() {
		return {};
	},
	mixins: [
		//inViewportMixin,
	],
	// Lifecycle hooks
	mounted(){},
	destroyed(){},
	computed: {},
	// Watch props and data
	watch: {},
	// Public API
	methods: {}
}
