<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgVideoPlayer
			:file="''"
			:isAutoplay="false"
			:isLoop="false"
			:expirationDate="''"
			:text="''"
			:copyright="''"
		></DgVideoPlayer>

	2022-03-20	improvement: added expirationDate prop, formated as Ymd
	2022-03-10	feature: autoplays now only if in viewport
	2022-02-23	feature: added props isAutoplay isLoop
	2021-09-24	init

-->

<template>
	<div class="DgVideoPlayer contentModule" :class="elmClasses" :data-dev-name="'DgVideoPlayer • isAutoplay:' + isAutoplay" v-if="hasFile">
		<div class="DgVideoPlayer__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgVideoPlayer__content contentModule__content fontStyle--s" :class="contentElmClasses">
				<template v-if="!isMediaExpired">
					<video
						:controls="true"
						:autoplay="_isAutoplay"
						:playsinline="isAutoplay"
						:muted="isAutoplay"
						:loop="isLoop"
						ref="video"
					>
						<source
							:src="src"
							:type="file.mime_type"
						/>
					</video><br/>
				</template>
				<template v-else>
					<DgMediaExpired
						:ratio="file ? _.get( file, 'width') + ':' + _.get( file, 'height') : undefined"
						:type="'video'"
					></DgMediaExpired>
				</template>
				<div class="DgVideoPlayer__text">
					<DbBu
						:caption="text"
						:copyright="copyright"
					></DbBu>
				</div>
			</div>
		</div>
		<!--
		<pre name="file" maxheight>{{file}}</pre>
		<pre name="isMediaExpired" maxheight>{{isMediaExpired}}</pre>
		-->
	</div>
</template>

<script>
	import inViewportMixin from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/inViewport/v2/inViewport.mixin.js'
	import EventBus from '@/helper/EventBus.js'
	import formatDateToYYYYMMDD from '@/helper/formatDateToYYYYMMDD'
	import DbBu from '@/components/molecules/DbBu.vue'
	import DgMediaExpired from '@/components/molecules/DgMediaExpired.vue'

	export default {
		name: 'DgVideoPlayer',
		components: {
			DbBu,
			DgMediaExpired,
		},
		mixins: [ inViewportMixin ],
		props: {
			file: {
				type     : [Object, Boolean],
				default  : ()=>{ return {} },
				required : false,
			},
			isAutoplay: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			isLoop: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			expirationDate: {
				type     : [String],
				default  : '',
				required : false,
			},
			text: {
				type     : [String, Boolean],
				default  : '',
				required : false,
			},
			copyright: {
				type     : [String, Boolean],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			inViewport: {
				handler: function( to, from ) {
					if( to.now && this.isAutoplay ){
						//console.log( this.$options.name, '• watch.inViewport', 'play', this.$refs.video )

						if( this.$refs.video ) this.$refs.video.play()
					}
					else{
						//console.log( this.$options.name, '• watch.inViewport', 'stop', this.$refs.video )

						if( this.$refs.video ) this.$refs.video.pause()
					}
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--size-' + this.size )

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				return classes
			},
			isMediaExpired(){
				let isExpired = false
				const expirationDateInt = this.expirationDate ? parseInt( this.expirationDate ) : undefined
				const todayDateInt = parseInt( formatDateToYYYYMMDD( undefined, '' ) )

				if( expirationDateInt && expirationDateInt <= todayDateInt ) isExpired = true

				/*
				console.log('expirationDateInt:', expirationDateInt)
				console.log('todayDateInt:', todayDateInt)
				*/

				return isExpired
			},
			hasFile(){
				return this._.isObject( this.file )
			},
			src(){
				// hack to force video thumbnail on ios
				// see: https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/
				let src = this.file ? this.file.url + '#t=0.001' : ''

				return src
			},
			_isAutoplay(){
				let _isAutoplay = this.inViewport.fully ? this.isAutoplay : false

				//console.log( this.$options.name, '• _isAutoplay:', _isAutoplay, this.$refs.video)

				return _isAutoplay
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgVideoPlayer { // debug
		[showBorders2] & {}
	}
	.DgVideoPlayer { // layout
		// grid
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }
		.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }

		@media @mq[sm] {
			// grid
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }
		}
	}
	.DgVideoPlayer { // styling
		@height : 5.45em; // 120px

		video { width: 100%; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
