<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgText
			:fontSize="'m'"
			:hasIndent="false"
			:isFolded="false"
			:text="''"
		></DgText>

	2022-02-23	feature: added prop isFolded
	2021-09-24	init

-->

<template>
	<div class="DgText contentModule" :class="elmClasses" :data-dev-name="'DgText • ' + fontSize">
		<div class="DgText__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgText__content contentModule__content" :class="contentElmClasses">
				<template v-if="isFolded">
					<MhSlideToggle
						:isOpen="doSlideToggle"
						:minHeight="foldedHeight + 'em'"
						@stateChange="slideToggleState = $event"
					>
						<div class="DgText__text richText hyphenate hideIfEmpty" v-html="text"></div>
					</MhSlideToggle>
					<a class="DgText__foldBtn font--bold" @click="doSlideToggle = !doSlideToggle">
						<div class="animatedElm">
							<MhIcon class="DgText__foldBtnIcon "
								:type="['closed', 'closing'].includes( slideToggleState ) ? 'plus' : 'minus'"
								:isSpinning="false"
							></MhIcon>
							<span class="DgText__foldBtnText">
								{{ ['closed', 'closing'].includes( slideToggleState ) ? $t('button.readMore') : $t('button.readLess') }}
							</span>
						</div>
						<span class="DgText__foldBtnShadow" :style="foldBtnShadowStyles"></span>
					</a>
				</template>
				<template v-else>
					<div class="DgText__text richText hyphenate hideIfEmpty" v-html="text"></div>
				</template>
			</div>
		</div>

		<!--
		<DgEditComponentInner></DgEditComponentInner>
		-->

		<!--
		<pre name="slideToggleState">{{slideToggleState}}</pre>
		<pre name="$attrs">{{$attrs}}</pre>
		<pre name="$props">{{$props}}</pre>
		<pre name="parseFloat($attrs.foldedHeight)">{{parseFloat($attrs.foldedHeight)}}</pre>
		-->
	</div>
</template>

<script>
	const Color = require('color')

	import EventBus from '@/helper/EventBus.js'
	import MhIcon from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhIcon/v2/MhIcon.vue'
	import MhSlideToggle from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhSlideToggle/v1/MhSlideToggle.vue'
	import inViewportMixin from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/inViewport/v2/inViewport.mixin.js'
	//import DgEditComponentInner from '@/components/editor/DgEditComponentInner.vue'

	export default {
		name: 'DgText',
		components: {
			MhIcon,
			MhSlideToggle,
			//DgEditComponentInner,
		},
		mixins: [ inViewportMixin ],
		props: {
			fontSize: {
				type     : [String],
				default  : 'm',
				required : false,
			},
			hasIndent: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			isFolded: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			foldedHeight: {
				type     : [String, Number],
				default  : '5',
				required : false,
			},
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {
				doSlideToggle : false,
				slideToggleState : '',
				// wird via mounted() anhand der --section-bgColor neu gesetzt, sofern vorhanden
				fadeOutStartColor : 'rgba(255, 255, 255, 0.001)',
				fadeOutStopColor : 'rgba(255, 255, 255, 1)',
			}
		},
		watch: {
			inViewport: {
				handler: function( to, from ) {
					if( to.now ){
						const animateElm = this.$el ? this.$el.querySelector('.animatedElm') : null
						if( animateElm ) animateElm.classList.add('bounceIn')
					}
					else{
						const animateElm = this.$el ? this.$el.querySelector('.animatedElm') : null
						if( animateElm ) animateElm.classList.remove('bounceIn')
					}
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.hasIndent ) classes.push( this.$options.name + '--hasIndent')
				if( this.inViewport.now ) classes.push( this.$options.name + '--isInViewport')

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				classes.push( 'fontStyle--' + this.fontSize )

				return classes
			},
			foldBtnShadowStyles(){
				let styles = {}
				const startColor = this.fadeOutStartColor
				const stopColor = this.fadeOutStopColor

				styles.background = `
					linear-gradient(
						180deg,
						${startColor},
						${stopColor}
					)
				`

				return styles
			}
		},
		methods: {
			parseFloat( val ){
				return parseFloat( val )
			},
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){},
		mounted(){
			// 2022-10-04	wegen dem blöden iOS Safari werden die farben des gradients jetzt direkt via js gesetzt
			//				iOS Safari benötigt tatsächlich zwei mal die gleiche Farbe als RGBA, einmal mit sehr niedriger alpha
			//				(weil das transparent keyword nicht funktioniert) und einmal mit alpha von 1
			//				um den linear-gradient fehlerfrei darstellen zu können. verrückt, und das im jahr 2022 m(
			setTimeout(()=>{
				const parentDgSectionEl = this.$el.closest('.DgSection') // get the parent section

				if( parentDgSectionEl ){
					const parentDgSectionBgColor = window.getComputedStyle( parentDgSectionEl ).getPropertyValue('--section-bgColor')

					if( parentDgSectionBgColor ) {
						const fadeOutColor = new Color( parentDgSectionBgColor )
						const fadeOutStartColorRgba = fadeOutColor.alpha( 0.001 ).toString({format: "rgba"})
						const fadeOutStopColorRgba = fadeOutColor.alpha( 1 ).toString({format: "rgba"})

						this.fadeOutStartColor = fadeOutStartColorRgba
						this.fadeOutStopColor = fadeOutStopColorRgba

						/*
						console.log('parentDgSectionEl:', parentDgSectionEl)
						console.log('parentDgSectionBgColor:', parentDgSectionBgColor)
						console.log('fadeOutColor:', fadeOutColor)
						console.log('fadeOutStartColorRgba:', fadeOutStartColorRgba)
						console.log('fadeOutStopColorRgba:', fadeOutStopColorRgba)
						console.log('----')
						*/
					}
				}
			}, 50)
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgText { // debug
		[showBorders2] & {
			&__foldBtn {
				outline: 1px solid blue;
			}
			&__foldBtn > * {
				outline: 1px solid red;
			}
		}
	}
	.DgText { // layout
		.DgGrid--cols-1 &__content { grid-column: 3 / span 8; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }
		.DgGrid--cols-1 &--hasIndent &__content { grid-column: 4 / span 7; }
		.DgGrid--cols-2 &--hasIndent &__content { grid-column: 2 / span 5; }

		@media @mq[sm] {
			// grid
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-1 &--hasIndent &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &--hasIndent &__content { grid-column: 1 / span 12; }
		}

		&__content {
			// 2022-03-05	needed to prevent overflow for very narrow texts and therefor bigger foldBtnShadow elm
			// 2022-03-08	wieder raus weil damit die schönen fadein animationen abgeschnitten werden
			// overflow: hidden;
		}
		&__foldBtn {
			position: relative;
			display: flex;
			align-items: center;
		}
		&__foldBtn .animatedElm {
			display: flex;
			align-items: center;
		}
		&__foldBtnShadow {
			position: absolute;
			top: -3.5em; left: 0; right: 0;
			height: 3.5em;
			//outline: 1px solid blue;
		}
	}
	.DgText { // styling
		&__foldBtn {
			padding: 0.5em 0;
			color: var(--swatch-cta-darken);
		}
		&__foldBtnIcon {
			--stroke-width : 0.15em;
			--height : 1.5em;
			--width : 1.5em;

			margin-left: -0.25em;
			margin-right: 0.25em;
		}
		&__foldBtnShadow {
			transition: all 0.35s ease;
			// 2022-10-04  wegen dem blöden iOS Safari werden die farben des gradients jetzt direkt via js gesetzt
			/*
			background: linear-gradient(
				rgba(0,0,0,0.01),
				rgba(87,87,87,1),
			);
			background-image: linear-gradient(
				180deg,
				rgba(87,87,87,0.01),
				rgba(87,87,87,1)
			);
				*/
				//var(--section-bgColor, white)
				//var(--section-bgColor, white)
		}

		//&--isInViewport &__foldBtn { opacity: 0.5; }

		.MhSlideToggle--isOpen + &__foldBtn &__foldBtnShadow,
		.MhSlideToggle--isOpening + &__foldBtn &__foldBtnShadow{
			opacity: 0;
			pointer-events: none;
		}
		.MhSlideToggle--isClosed + &__foldBtn &__foldBtnShadow,
		.MhSlideToggle--isClosing + &__foldBtn &__foldBtnShadow{
			opacity: 1;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
