// normalize fetched json
// put contentModules in columns array

import { has, get, set, map, forEach, cloneDeep } from 'lodash'

// function to rename old key
function renameKey ( obj, old_key, new_key ) {
	// check if old key = new key
	if ( old_key !== new_key ) {
		Object.defineProperty( obj, new_key, // modify old key
			// fetch description from object
			Object.getOwnPropertyDescriptor( obj, old_key ) );
		delete obj[old_key];                // delete old key
	}
}

export default ( post ) => {
	let _post = cloneDeep( post )
	const sections = get( _post, 'acf.sections' )

	// walk the sections
	forEach( sections, ( section, i ) => {
		const grids = get( section, 'grids' )

		// bei den guides musste ich im backend die acfs in eine gruppe packen.
		// hier hole ich die farben wieder raus und stelle sie als direkte props
		// der section zur verfügung
		if ( has( section, 'general' ) ) {
			section.bgColor = get( section, 'general.bgColor' )
			section.textColor = get( section, 'general.textColor' )
		}

		if ( has( section, 'articles' ) ) {
			const articles = []

			forEach( section.articles, (article)=>{
				const articlePost = article.article

				articles.push({
					'id' : articlePost.ID,
					'slug' : articlePost.post_name,
					'title' : articlePost.post_title,
					'buttonText' : article.title,
				})
			})
			section.articles = articles
		}

		// im backend musste ich das contentModules-array mit einem eindeutigen namen versehen.
		// hier hole ich sie wieder raus und packe sie als prop "columns" dem grid hinzu
		forEach( grids, ( grid, j ) => {

			// rename contentModules to columns
			if ( 'columns_1' === grid.acf_fc_layout ) {
				set( grid, 'countColums', 1 )
				set( grid, 'columns', [
					get( grid, 'contentModules1_contentModules' ),
				] )
				delete grid.contentModules1_contentModules
			}
			if ( 'columns_2' === grid.acf_fc_layout ) {
				set( grid, 'countColums', 2 )
				set( grid, 'columns', [
					get( grid, 'contentModules1_contentModules' ),
					get( grid, 'contentModules2_contentModules' ),
				] )
				delete grid.contentModules1_contentModules
				delete grid.contentModules2_contentModules
			}

		} )
	} )

	//console.log( _post )
	//console.log( sections )

	return _post
}