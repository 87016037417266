<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgMediaExpired
^			:ratio="''"
^			:type="'image'"
		></DgMediaExpired>

	2022-03-20	refactor: added optional ratio prop, formated like '16:9'
	2022-03-20	refactor: added optional type prop
	2022-03-20	refactor: removed image prop cause we need this for videos and audio also
	2022-01-21	init

-->

<template>
	<div class="DgMediaExpired fontStyle--s" :class="elmClasses" :style="elmStyles">
		<div class="DgMediaExpired__inner">
			<div class="DgMediaExpired__svgWrapper">
				<svg class="DgMediaExpired__svg"
					viewBox="0 0 697 538"
					preserveAspectRatio="none"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path d="M1 1L696 537" vector-effect="non-scaling-stroke" />
					<path d="M696 1L0.99999 537" vector-effect="non-scaling-stroke" />
				</svg>
			</div>
			<div class="DgMediaExpired__textWrapper">
				<p class="DgMediaExpired__text" v-if="'image' === type">
					Abbildung aus lizenzrechtlichen Gründen nicht mehr verfügbar.
				</p>
				<p class="DgMediaExpired__text" v-if="'video' === type">
					Video aus lizenzrechtlichen Gründen nicht mehr verfügbar.
				</p>
				<p class="DgMediaExpired__text" v-if="'audio' === type">
					Audio-Datei aus lizenzrechtlichen Gründen nicht mehr verfügbar.
				</p>
			</div>
		</div>

		<!--
		<pre name="ratio">{{ratio}}</pre>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DgMediaExpired',
		components: {},
		mixins: [],
		props: {
			ratio: {
				type     : [String],
				default  : '',
				required : false,
			},
			type: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			elmStyles(){
				let styles = {}

				let width = this.ratio ? this.ratio.split(':')[0] : undefined
				let height = this.ratio ? this.ratio.split(':')[1] : undefined
				/*
				let width = this._.get( this.image, 'width' )
				let height = this._.get( this.image, 'height' )
				*/

				if( width && height ) styles.paddingBottom = height && width ? (height / width) * 100 + '%' : ''

				return styles
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgMediaExpired { // debug
		[showBorders2] & {}
	}
	.DgMediaExpired { // vars
		--bgColor : lighten( black, 90 );
		--color : lighten( black, 50 );
	}
	.DgMediaExpired { // layout
		position: relative;

		&__inner {
			//position: relative;
		}

		&__textWrapper {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__svgWrapper {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			pointer-events: none;
		}
		&__svg {
			height: 100%; width: 100%;
		}
	}
	.DgMediaExpired { // styling
		background-color: var(--bgColor);
		color: var(--color);

		&__textWrapper {
			padding: 1em;
		}
		&__svgWrapper {
			border: 1px solid currentColor;
			stroke: currentColor;
			opacity: 0.5;
			padding: 1em;
		}
		&__text {
			background-color: red;
			background-color: var(--bgColor);
			text-align: center;
			font-weight: normal;
			padding: 0.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
